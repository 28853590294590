import Vue from 'vue'
import Vuex from 'vuex'
import worker from './modules/worker'
import Public from '@/api/public'
import router from '@/router'
Vue.use(Vuex)
// 重置vuex的值
export const initState = {
  requestList: [], //请求标志的列表，用于路由跳转的时候清除请求
  token: '', //token
  user: '', //用户信息
  accountid: '', //身份id
  loginLogo: false, //登录标志
  dynamicRoute: [], //身份路由
  left_nav: [], // 左侧导航
  top_nav: [], // 顶部导航
  active_top_nav: '', //顶部导航选中的id，默认为第一个被选中
  account: '', //身份的各种信息
  componentName: '',
  progress: '', //上传进度
  year: '', //当前需要导入常住居民的年度
  loading: {
    loading: false,
    title: '',
    color: 'rgba(255,255,255,0.8)'
  },
  dic: '', //字段字典
  active_left_nav: '' //左边导航选中的id，默认为第一个被选中
}
export default new Vuex.Store({
  modules: { worker },
  state: { ...initState },
  mutations: {
    setDic(state, dic) {
      state.dic = dic
    },
    changeLoading(state, loading) {
      state.loading = loading
    },
    setYear(state, year) {
      state.year = year
    },
    changeComponent(state, name, data) {
      state.componentName = name
      console.log(name)
      console.log(data)
    },
    setProgress(state, progress) {
      state.progress = progress
    },
    // 初始化数据
    init(state, data) {
      for (let i in data) {
        state[i] = data[i]
      }
    },
    // 将请求标志添加到请求列表中
    pushRequestList: (state, request) => state.requestList.push(request),
    // 路由跳转 清除上个页面的请求
    cancleRequest(state) {
      state.requestList.forEach(item => {
        item.cancel('router_change')
      })
      state.requestList = []
    },
    // 设置token
    setToken: (state, token) => (state.token = token),
    // 设置user
    setUser: (state, user) => (state.user = user),
    // 设置accountid
    setAccountid: (state, accountid) => (state.accountid = accountid),
    // 设置accountid
    setAccount: (state, account) => (state.account = account),

    // 设置登录标志
    setLoginLogo: (state, loginLogo) => (state.loginLogo = loginLogo),
    // 存储身份路由
    setDynamicRoute: (state, router) => (state.dynamicRoute = router),
    // 左侧导航
    setLeftNav: (state, top_nav_id) => {
      state.dynamicRoute.some(item => {
        if (item.id === top_nav_id) {
          if (item.has_child) {
            let left_nav = item.children.map(left_item => {
              if (/-区县$/.test(left_item.meta.title)) {
                left_item.meta.title = left_item.meta.title.replace('-区县', '')
              }
              if (/-省市$/.test(left_item.meta.title)) {
                left_item.meta.title = left_item.meta.title.replace('-省市', '')
              }
              if (/-司法所$/.test(left_item.meta.title)) {
                left_item.meta.title = left_item.meta.title.replace('-司法所', '')
              }
              return left_item
            })
            state.left_nav = left_nav

            state.active_left_nav = left_nav[0]
          }
          // 退出some循环
          return true
        }
      })
    },
    // 顶部导航
    setTopNav: (state, top_nav) => {
      state.top_nav = top_nav
      state.active_top_nav = top_nav[0]
    },
    set_active_left_nav: (state, active_left_nav) => (state.active_left_nav = active_left_nav),
    set_active_top_nav: (state, active_top_nav) => (state.active_top_nav = active_top_nav)
  },
  actions: {
    // 设置全局字典
    setDic: ({ commit }, jump) => {
      Public.components({
        Dictionary: [
          'mz',
          'xb',
          'sczt',
          'whcd',
          'grzdsxbh',
          'zzmm',
          'dwxz',
          'jkzk',
          'byzy',
          'zwjb',
          'bmfs',
          'zylb',
          'scbtgyy',
          'bmfs',
          'zylb',
          'rddb',
          'zxwy',
          'jhlx',
          'nrgs',
          'rzzt',
          'sfflgzz',
          'sfzbry',
          'sflyys',
          'sfxbwy',
          'sfcwhgzry',
          'dwjb'
        ]
      }).then(res => {
        commit('setDic', res.Dictionary)
        if (jump) {
          router.push('/choose')
        }
      })
    },
    setToken: ({ commit }, token) => commit('setToken', token),
    setUser: ({ commit }, user) => commit('setUser', user),
    setAccountid: ({ commit }, accountid) => commit('setAccountid', accountid),
    setLoginLogo: ({ commit }, loginLogo) => commit('setLoginLogo', loginLogo)
  },
  getters: {
    token: state => state.token,
    user: state => state.user,
    accountid: state => state.accountid,
    account: state => state.account,
    loginLogo: state => state.loginLogo,
    router: state => state.dynamicRoute,
    top_nav: state => state.top_nav,
    left_nav: state => state.left_nav,
    active_left_nav: state => state.active_left_nav,
    active_top_nav: state => state.active_top_nav,
    dynamicRoute: state => state.dynamicRoute,
    componentName: state => state.componentName,
    progress: state => state.progress,
    year: state => state.year,
    loading: state => state.loading,
    dic: state => state.dic
  }
})
