// import vueToPdf from 'vue-to-pdf'
// import Vue from 'vue'
// Vue.use(vueToPdf)
// 导出页面为PDF格式
import html2Canvas from 'html2canvas'
import JsPDF from './jspdf'
import Vue from 'vue'
const htmlToPdf = {
  install(Vue) {
    Vue.prototype.$getPdf = function(title, query) {
      html2Canvas(document.querySelector(query), {
        allowTaint: true,
        useCORS: true,
        taintTest: false,
        dpi: window.devicePixelRatio * 4
      }).then(function(canvas) {
        let contentWidth = canvas.width
        let contentHeight = canvas.height
        let pageHeight = (contentWidth / 592.28) * 841.89
        let leftHeight = contentHeight
        let position = 0
        let imgWidth = 595.28
        let imgHeight = (592.28 / contentWidth) * contentHeight
        let pageData = canvas.toDataURL('image/jpeg', 1.0)
        let PDF = new JsPDF('', 'pt', 'a4')
        if (leftHeight < pageHeight) {
          PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
        } else {
          while (leftHeight > 0) {
            PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
            leftHeight -= pageHeight
            position -= 841.89
            if (leftHeight > 0) {
              PDF.addPage()
            }
          }
        }
        PDF.save(title + '.pdf')
      })
    }
  }
}
Vue.use(htmlToPdf)
