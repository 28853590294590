import Vue from 'vue'
import VueRouter from 'vue-router'
import State from '@/store'
Vue.use(VueRouter)
import baseroute from './baseroute'

import Login from '@/api/login'
import { resetRouter } from '@/router/permission'

const router = new VueRouter({
  routes: baseroute,
  mode: 'history',
  base: process.env.BASE_URL
})
router.beforeEach((to, from, next) => {
  // 清除上个页面未完成的请求
  State.commit('cancleRequest')

  setTimeout(() => {
    let { loginLogo, token, accountid, dynamicRoute } = State.getters
    if (token && loginLogo && accountid) {
      if (dynamicRoute.length > 0) {
        if (to.path === '/login') {
          next(dynamicRoute[0].redirect)
        } else {
          next()
        }
      } else {
        Login.identityRoute(accountid).then(res => {
          resetRouter(res[0].children)
          let route = res[0].children
          let top_nav = route.map(item => item)
          State.commit('setTopNav', top_nav)
          // 根据顶部导航来修改侧边导航，初始化的时候是第一个顶部路由被点击中
          let { path } = to
          let really_path = top_nav.some((item, index) => {
            let reg = new RegExp('^' + item.path)
            if (reg.test(path)) {
              State.commit('set_active_top_nav', item)
              State.commit('setLeftNav', top_nav[index].id)
              return true
            }
          })
          if (!really_path) {
            State.commit('setLeftNav', top_nav[0].id)
          }
          if (to.path !== '/login') {
            // 登录了未注册路由
            next(path)
          } else {
            next(State.getters.dynamicRoute[0].redirect)
          }
        })
      }
      // 如果有token，有accountid，有登录成功状态的话，就不能进入登录页
      // 如果没有获取路由的话自动获取
    } else {
      // 否则必须进入登录页
      if (to.path !== '/login') {
        next('/login')
      } else {
        next()
      }
    }
  }, 0)
})
export default router
