import router from './index'
import Router from 'vue-router'
import baseroute from './baseroute'

import State from '@/store'
// 动态配置路由
function setComponents(data) {
  let result = [...data]
  result.forEach(item => {
    if (item.has_child) {
      item.redirect = item.children[0].path
      item.component = () => import(`@/views${item.path}`)
      item.children = setComponents(item.children)
    } else {
      item.component = () => import(`@/views${item.path}`)
    }
  })
  return result
}
// 重置路由
export function resetRouter(data) {
  let dynamic = setComponents(data)
  State.commit('setDynamicRoute', dynamic)
  baseroute[2].children = dynamic
  router.matcher = new Router({ mode: 'history', routes: [] }).matcher
  router.addRoutes(baseroute)
}
