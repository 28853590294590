import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

import './style/index.scss'
import '@/plugin'

import personInfo from '@/components/personInfo'
Vue.component('personInfo', personInfo)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

Vue.directive('focus', {
  // 当被绑定的元素插入到 DOM 中时……
  inserted: function(el) {
    if (Object.prototype.toString.call(el).slice(8, -1) === 'HTMLDivElement') {
      el.children[0].focus()
    }
    if (Object.prototype.toString.call(el).slice(8, -1) === 'HTMLInputElement') {
      el.focus()
    }
  }
})

Vue.directive('auth', {
  // 当被绑定的元素插入到 DOM 中时……
  // 允许插入多个权限，用,分割 eg： 'login,submit'
  inserted: function(el, binding, vnode) {
    let { value } = binding

    let value_list = value.split(',')
    let { auth } = vnode.context.$store.getters.active_left_nav.meta
    value_list.forEach(value_item => {
      let have = auth.some(item => {
        if (item === value_item) {
          return true
        }
      })
      if (!have && vnode.context.auth && vnode.context.auth[value_item]) {
        vnode.context.auth[value_item] ? (vnode.context.auth[value_item] = false) : ''
      }
    })
  }
})

Vue.filter('csrq', function(data) {
  data = data.slice(6, 14)
  let year = data.slice(0, 4)
  let month = data.slice(4, 6)
  let day = data.slice(6, 8)
  return `${year}-${month}-${day}`
})
