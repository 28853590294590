import { tongke } from '@/axios'
class Public {
  /**
   * @functionName components
   * @param { Object }  params
   * @param { Object }  params.LoginLog  登录日志
   * @param { Object }  params.OrgGradeTree 组织类型ID
   * @param { Object }  params.OrgTree  组织ID
   * @param { Object }  params.RoleRuleTree 角色ID
   * @param { Object }  params.RoleTree 组织ID
   * @param { Object }  params.ImportProgress 模块标识（czjm）
   * @Description   // 组件接口
   * @return { Object }
   */
  components(params) {
    return tongke.post('/l/component', { methods: params })
  }
  /**
   * @functionName components
   * @param { Object }  params
   * @param { Object }  params.page
   * @param { Object }  params.size
   * @param { Object }  params.keyword 关键词
   * @Description   // 操作日志列表
   * @return { Object }
   */
  logList(params) {
    return tongke.get('/oplog', { params })
  }
}
export default new Public()
