<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import Login from './api/login';
import { resetRouter } from '@/router/permission';

export default {
  name: 'app',
  created() {
    let userInfo = this.$myget('userInfo');
    // let accountid = this.$myget('accountid');
    let account = this.$myget('account');
    let loginLogo = this.$myget('loginLogo');
    // 如果userInfo不对，就丢弃所有本地存储
    if (!userInfo || !userInfo.token || !userInfo.user) {
      console.log('丢弃');
      console.log(userInfo);
      console.log(account);
      console.log(loginLogo);
      this.$myremove();
      return;
    }
    this.$store.commit('setToken', userInfo.token);
    this.$store.commit('setUser', userInfo.user);

    // 设置登录状态
    if (account) {
      this.$store.commit('setAccount', account);
      this.$store.commit('setAccountid', account.id);
      this.$store.dispatch('setDic').then((res) => {
        console.log(res);
        Login.identityRoute(account.id).then((res) => {
          resetRouter(res);
          let route = res[0].children;
          let top_nav = route.map((item) => item);
          this.$store.commit('setTopNav', top_nav);
          // 根据顶部导航来修改侧边导航，初始化的时候是第一个顶部路由被点击中
          this.$store.commit('setLeftNav', top_nav[0].id);
        });
      });
    }
    // 设置登录状态
    if (loginLogo) {
      this.$store.commit('setLoginLogo', loginLogo);
    }
  },
};
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}
</style>
