import Vue from 'vue'
// import 'element-ui/lib/theme-chalk/index.css'

import {
  Button,
  Message,
  MessageBox,
  Form,
  FormItem,
  Select,
  Option,
  Input,
  Table,
  TableColumn,
  Dialog,
  Pagination,
  Loading,
  Switch,
  Main,
  Radio,
  RadioGroup,
  RadioButton,
  Cascader,
  Tooltip,
  InputNumber,
  DatePicker,
  Col,
  steps,
  step,
  Link,
  Tree,
  Popover,
  Progress,
  Upload
} from 'element-ui'
Vue.use(Button)
Vue.use(FormItem)
Vue.use(Form)
Vue.use(Select)
Vue.use(Option)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(TableColumn)
Vue.use(Table)
Vue.use(Dialog)
Vue.use(Pagination)
Vue.use(Loading)
Vue.use(Switch)
Vue.use(Main)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(RadioButton)
Vue.use(Cascader)
Vue.use(Tooltip)
Vue.use(DatePicker)
Vue.use(Col)
Vue.use(step)
Vue.use(steps)
Vue.use(Link)
Vue.use(Tree)
Vue.use(Popover)
Vue.use(Progress)
Vue.use(Upload)
Vue.prototype.$message = Message
Vue.prototype.$alert = MessageBox
Vue.prototype.$confirm = MessageBox.confirm
