<template>
  <section class="updateInfo_component">
    <div class="header">
      <div class="tools">
        <el-button
          :type="componentName==='baseInfo'?'primary':''"
          size="small"
          :class="componentName==='baseInfo'?'':'btn_active'"
          @click="changeComponent('baseInfo','基本信息',1)"
        >基本信息</el-button>
        <el-button
          :type="componentName==='companyInfo'?'primary':''"
          size="small"
          :class="componentName==='companyInfo'?'':'btn_active'"
          @click="changeComponent('companyInfo','单位信息',2)"
        >单位信息</el-button>
        <el-button
          :type="componentName==='perfectInfo'?'primary':''"
          size="small"
          :class="componentName==='perfectInfo'?'':'btn_active'"
          @click="changeComponent('perfectInfo','个人简历',3)"
        >个人简历</el-button>
        <el-button
          :type="componentName==='upload'?'primary':''"
          size="small"
          :class="componentName==='upload'?'':'btn_active'"
          @click="changeComponent('upload','身份证相关信息',4)"
        >上传文件</el-button>
      </div>
      <div class="submit">
        <el-button size="small" @click="cancel">关闭</el-button>
      </div>
    </div>
    <div class="main" v-loading="loading">
      <div class="main-header">
        <div class="router2-name">{{title}}</div>
      </div>
      <baseInfo
        :info="info"
        v-show="componentName==='baseInfo' "
        :Dictionary="Dictionary"
        ref="baseInfo"
        @changeComponent="changeComponent"
      ></baseInfo>
      <companyInfo
        :info="info"
        v-show="componentName==='companyInfo' "
        :Dictionary="Dictionary"
        @changeComponent="changeComponent"
        ref="companyInfo"
      ></companyInfo>
      <perfectInfo
        :info="info"
        v-show="componentName==='perfectInfo'"
        :Dictionary="Dictionary"
        @changeComponent="changeComponent"
        ref="perfectInfo"
      ></perfectInfo>
      <upload
        :info="info"
        v-show="componentName==='upload'"
        :Dictionary="Dictionary"
        @changeComponent="changeComponent"
        ref="upload"
        @close="cancel"
      ></upload>
    </div>
  </section>
</template>

<script>
import baseInfo from './baseInfo.vue';
import companyInfo from './companyInfo.vue';
import perfectInfo from './personalResume.vue';
import upload from './upload.vue';
import Public from '@/api/public';
export default {
  name: '',
  components: {
    baseInfo,
    companyInfo,
    perfectInfo,
    upload,
  },
  data() {
    return {
      title: '基本信息',
      componentName: '',
      step: 1,
      info: {},
      changeLogo: true,
      form_list: ['baseInfo', 'companyInfo', 'perfectInfo', 'upload'],
      Dictionary: {},
      loading: false,
      name: '',
    };
  },
  methods: {
    cancel() {
      this.$emit('closeInfo');
    },
    // 初始化，用身份证获取详情
    toggle(data) {
      this.name = data.xm + '--';
      this.loading = true;
      // this.info = data;
      Public.components({ PersonInfo: data.sfzhm })
        .then((res) => {
          this.info = res.PersonInfo;
          return this.changeComponent('baseInfo', '基本信息', 1);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    changeComponent(name, title, step) {
      if (name === this.componentName) {
        return;
      }
      if (!this.changeLogo) {
        return;
      } else {
        this.componentName = name;
        this.title = this.name + title;
        this.step = step;
        this.$nextTick(() => {
          if (this.$refs[this.componentName]) {
            this.$refs[this.componentName].toggle(this.info);
          }
        });
      }
    },
    changeInfo(data) {
      Object.assign(this.info, data);
    },
  },
  mounted() {},
  created() {
    this.Dictionary = this.$store.getters.dic;
  },
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
.updateInfo_component {
  background-color: #fff;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  padding: 0 20px;
  box-sizing: border-box;
  .header {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
  }
  .main {
    background-color: #fff;
    box-sizing: border-box;
    position: relative;
    flex: 1;
    padding: 0 20px;
    .main-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $h4;
      padding: 15px 0;
      font-weight: bold;
    }

    .form {
      padding: 10px;
      .el-form {
        border-bottom: none;
        .header-bgc {
          background-color: $border4;
        }
      }
    }
  }
}
</style>
