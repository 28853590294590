<template>
  <div class="checkCandidate-edit-baseInfo">
    <el-form ref="form" :model="form.data" label-width="120px" class="form">
      <el-col :span="8">
        <el-form-item label="姓名：">
          <el-input v-model="form.data.xm" v-if="form.data.xm" placeholder="请输入姓名" disabled></el-input>
          <el-input v-model="form.data.xm" v-else placeholder="暂无数据" disabled></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="性别：">
          <el-input v-model="form.data.xb" v-if="form.data.xb" placeholder="请选择性别" disabled></el-input>
          <el-input v-model="form.data.xb" v-else placeholder="暂无数据" disabled></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="重大事项：">
          <el-select
            v-model="form.data.grzdsxbh"
            v-if="form.data.grzdsxbh"
            placeholder="请选择重大事项"
            disabled
          >
            <el-option
              v-for="item in form.options.grzdsxbh.children"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-input v-else placeholder="暂无数据" disabled></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="民族：" prop="mz">
          <el-select v-model="form.data.mz" placeholder="请选择民族" disabled v-if="form.data.mz">
            <el-option
              v-for="item in form.options.mz.children"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-input v-else placeholder="暂无数据" disabled></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="政治面貌：" prop="zzmm">
          <el-select v-model="form.data.zzmm" v-if="form.data.zzmm" placeholder="请选择政治面貌" disabled>
            <el-option
              v-for="item in form.options.zzmm.children"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-input v-else placeholder="暂无数据" disabled></el-input>
        </el-form-item>
      </el-col>

      <el-col :span="8">
        <el-form-item label="报名方式：" prop="bmfs">
          <el-select v-model="form.data.bmfs" v-if="form.data.bmfs" placeholder="请选择报名方式" disabled>
            <el-option
              v-for="item in form.options.bmfs.children"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-input v-else placeholder="暂无数据" disabled></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="毕业院校：">
          <el-input v-model="form.data.byyx" v-if="form.data.byyx" placeholder="请输入毕业院校" disabled></el-input>
          <el-input v-model="form.data.byyx" v-else placeholder="暂无数据" disabled></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="文化程度：">
          <el-input v-model="form.data.whcd" v-if="form.data.whcd" placeholder="请选择文化程度" disabled></el-input>
          <el-input v-model="form.data.whcd" v-else placeholder="暂无数据" disabled></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="毕业专业：">
          <el-input v-model="form.data.byzy" v-if="form.data.byzy" placeholder="请输入毕业专业" disabled></el-input>
          <el-input v-model="form.data.byzy" v-else placeholder="暂无数据" disabled></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="身份证号码：" prop="sfzhm">
          <el-input
            v-model.number="form.data.sfzhm"
            v-if="form.data.sfzhm"
            placeholder="请输入身份证号码"
            disabled
          ></el-input>
          <el-input v-else placeholder="暂无数据" disabled></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="联系电话：" prop="lxdh">
          <el-input v-model="form.data.lxdh" v-if="form.data.lxdh" placeholder="请输入联系电话" disabled></el-input>
          <el-input v-else placeholder="暂无数据" disabled></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="QQ号码：">
          <el-input v-model="form.data.qqhm" v-if="form.data.qqhm" placeholder="请输入QQ号码" disabled></el-input>
          <el-input v-else placeholder="暂无数据" disabled></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="微信号：">
          <el-input v-model="form.data.wxh" v-if="form.data.wxh" placeholder="请输入微信号" disabled></el-input>
          <el-input v-else placeholder="暂无数据" disabled></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="电子邮箱：">
          <el-input v-model="form.data.dzyx" v-if="form.data.dzyx" placeholder="请输入电子邮箱" disabled></el-input>
          <el-input v-else placeholder="暂无数据" disabled></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="邮政编码：">
          <el-input v-model="form.data.yzbm" v-if="form.data.yzbm" placeholder="请输入邮政编码" disabled></el-input>
          <el-input v-else placeholder="暂无数据" disabled></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="13">
        <el-form-item label="户籍地：">
          <el-input placeholder="暂无数据" disabled v-model="hjd"></el-input>
        </el-form-item>
      </el-col>

      <el-col :span="11">
        <el-form-item label="户籍地详细地址：" prop="hjdxxdz" label-width="160px">
          <el-input
            v-model="form.data.hjdxxdz"
            v-if="form.data.hjdxxdz"
            placeholder="请输入户籍地详细地址"
            disabled
          ></el-input>
          <el-input v-else placeholder="暂无数据" disabled></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="13">
        <el-form-item label="居住地：">
          <el-input placeholder="暂无数据" disabled v-model="jzd"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="11">
        <el-form-item label="居住地详细地址：" prop="jzdxxdz" label-width="160px">
          <el-input
            v-model="form.data.jzdxxdz"
            v-if="form.data.jzdxxdz"
            placeholder="请输入居住地详细地址"
            disabled
          ></el-input>
          <el-input v-else placeholder="暂无数据" disabled></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="13">
        <el-form-item label="通信地址">
          <el-input placeholder="暂无数据" disabled v-model="txdz"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="11">
        <el-form-item label="通信地址详细地址：" prop="txdzxxdz" label-width="160px">
          <el-input
            v-model="form.data.txdzxxdz"
            v-if="form.data.txdzxxdz"
            placeholder="请输入通信地址详细地址"
            disabled
          ></el-input>
          <el-input v-else placeholder="暂无数据" disabled></el-input>
        </el-form-item>
      </el-col>
    </el-form>
    <div class="info">
      备注：带
      <span>*</span>号的为必填
    </div>
    <div class="nextStep">
      <el-button type="primary" @click="submit(true)">下一页</el-button>
    </div>
  </div>
</template>

<script>
import numberToString from '@/plugin/numberToString'
export default {
  name: 'baseInfo',
  props: {
    Dictionary: Object,
    info: Object,
  },
  data() {
    return {
      form: {
        data: {
          xm: '', //姓名,
          xb: '', //性别
          grzdsxbh: '', //个人重大事项编号
          sfzhm: '', //身份证号码
          mz: '', //民族
          zzmm: '', //政治面貌
          bmfs: '', //报名方式
          byyx: '', //毕业院校
          whcd: '', //文化程度
          byzy: '', //毕业专业
          qqhm: '', //QQ号码
          wxh: '', //微信号
          lxdh: '', //联系电话
          dzyx: '', //电子邮箱
          yzbm: '', //邮政编码
          jkzk: '', //健康状况
          hjd: '', //户籍地
          hjdxxdz: '', //户籍地详细地址
          jzd: '', //居住地
          jzdxxdz: '', //居住地详细地址
          txdz: '', //通信地址
          txdzxxdz: '', //通信地址详细地址
        },
        rules: {
          xm: { required: true, message: '请输入姓名', trigger: 'change' },
          xb: { required: true, message: '请选择性别', trigger: 'change' },
          mz: { required: true, message: '请选择民族', trigger: 'change' },
          zzmm: { required: true, message: '请选择政治面貌', trigger: 'change' },
          lxdh: { required: true, message: '请输入联系电话', trigger: 'change' },
          jzdxxdz: { required: true, message: '请输入居住地址详细地址', trigger: 'change' },
          sfzhm: { required: true, message: '请输入身份证号码', trigger: 'change' },
          // bmfs: { required: true, message: '请选择报名方式', trigger: 'change' },
        },
        options: {
          xb: { children: [] },
          grzdsxbh: { children: [] },
          mz: { children: [] },
          zzmm: { children: [] },
          whcd: { children: [] },
          bmfs: { children: [] },
        },
      },
      hjd: '',
      jzd: '',
      txdz: ''
    };
  },
  methods: {
    submit() {
      this.$emit('changeComponent', 'companyInfo', '单位信息', 2);
    },
    toggle(info) {
      this.form.options = this.Dictionary;

      numberToString([info], 'whcd', this.Dictionary.whcd,)
      let {
        xm,
        xb,
        bmfs,
        grzdsxbh,
        zzmm,
        byyx,
        whcd_a,
        byzy,
        sfzhm,
        lxdh,
        qqhm,
        wxh,
        dzyx,
        yzbm,
        hjd,
        hjdxxdz,
        jzd,
        jzdxxdz,
        txdz,
        txdzxxdz,
        mz,
      } = info;
      this.form.data = {
        grzdsxbh,
        zzmm,
        bmfs,
        byyx,
        whcd: whcd_a,
        mz,
        byzy,
        sfzhm,
        lxdh,
        qqhm,
        wxh,
        dzyx,
        yzbm,
        hjd,
        hjdxxdz,
        jzd,
        jzdxxdz,
        txdz,
        txdzxxdz,
        xm,
        xb,
      };
      if (!this.form.data.hjd) {
        this.hjd = '暂无数据';
      } else {
        let { province, city, area } = this.form.data.hjd;
        this.hjd = `${province ? province : ''}${city ? city : ''}${area ? area : ''}`
      }


      if (!this.form.data.jzd) {
        this.jzd = '暂无数据';
      } else {
        let { province, city, area } = this.form.data.jzd;
        let str = `${province ? province : ''}${city ? city : ''}${area ? area : ''}`
        this.jzd = str
      }


      if (!this.form.data.txdz) {
        this.txdz = '暂无数据';
      } else {
        let { province, city, area } = this.form.data.txdz;
        this.txdz = `${province ? province : ''}${city ? city : ''}${area ? area : ''}`
      }
      console.log(this.form.data)
      console.log(this.jzd)
      console.log(this.hjd)
      console.log(this.txdz)

    },
  },
  mounted() { },
  created() { },
  computed: {

  },
  watch: {},
};
</script>

<style scoped lang="scss">
.checkCandidate-edit-baseInfo {
  display: flex;
  flex-direction: column;
  .el-form {
    .el-select {
      width: 100%;
    }
  }
  .info {
    margin-top: 50px;
    font-size: 14px;
    color: #0079fe;
    span {
      color: #f56c6c;
    }
  }
  .nextStep {
    text-align: center;
  }
}
::v-deep .distpicker-address-wrapper {
  display: flex;
  label {
    flex: 1;
    select {
      width: 100%;
      box-sizing: border-box;
      &:focus {
        outline: none;
        border-color: $primary;
      }
    }
  }
  label:nth-of-type(-n + 2) {
    margin-right: 10px;
  }
  .el-input__suffix {
    display: none;
  }
}
</style>
