/* eslint-disable */

import Vue from 'vue'
import State from '@/store'

import fs from 'file-saver'
import * as ExcelJs from 'exceljs';

/**
 * @functionName tableToExcel
 * @param { Object } options 配置参数
 * @param { Object } options.name //要导出的文件名
 * @param { Array } options.header  //表头的信息与表头配置
 * @param { Array } options.data //要导出的数据
 * @param { Number } options.deep //如果是二级表头的话，需要设置deep为2------根据是否设置deep来判断当前单元格层级
 * @param { Object } options.header[0]  //表头第一个元素
 * @param { String } options.header[0].label //表头中单元格的名称
 * @param { String } options.header[0].key  //表中单元格在data中查找值的属性名,支持a.b.c的格式获取内部对象
 * @param { Number || String } options.header[0].length //表中单元格的宽度
 * @param { String } options.header[0].value //存在部分值在data中没有值的现象，如果需要展示没有值的某一列单元格，就设置此项
 * @param { String } options.header[0].label //表头中单元格的名称
 * @param { String } options.header[0].children //表头中单元格的下层单元格
 * @param { String } options.header[0].rowspan //表头中单元格的行数（有子单元格就是1，没有子单元格就是2）
 * @param { String } options.header[0].colspan //表头中单元格所占的列数（无子单元格就是1，否则根据子单元格数量来算）
 * @param { String } options.header[0].children[0].label //表头中单元格的名称
 * @param { String } options.header[0].children[0].key //表中单元格在data中查找值的属性名,支持a.b.c的格式获取内部对象
 * @param { String } options.header[0].children[0].length //表中单元格的宽度
 * @Description  //自定义导出excel表格
 * @return { Object }
 */
function tableToExcel(options) {
    let {header, data, name, deep} = options
    // 临时添加上出生日期，防止出生日期为空的情况发生
    data.forEach(item => {
        if (!item.csrq && item.sfzhm) {
            item.csrq = getCsrqFromSfzhm(item.sfzhm)
        }
    })
    let container = document.createElement('div')
    let table = document.createElement('table')
    table.style.fontSize = '14px'
    // 设置左右上下边距为0
    table.setAttribute('cellspacing', '0')
    table.setAttribute('cellspacing', '0')
    table.setAttribute('border', '1')
    let thead = document.createElement('thead')

    // 目前只适配一级和二级表头,根据deep判断，默认是一级
    if (deep === 2) {
        let tr1 = document.createElement('tr')
        let tr2 = document.createElement('tr')
        tr1.style.height = '35px'
        tr2.style.height = '35px'
        header.forEach(item => {
            console.log(item)
            if (item.children) {
                // 设置上级表头
                let td_father = document.createElement('td')
                td_father.innerText = item.label
                td_father.setAttribute('colspan', item.colspan)
                tr1.appendChild(td_father)
                // 设置二级表头
                item.children.forEach(item_child => {
                    let td_child = document.createElement('td')
                    td_child.innerText = item_child.label
                    if (item_child.length) {
                        td_child.style.width = item_child.length + 'px'
                    } else {
                        td_child.style.width = '100px'
                    }
                    tr2.appendChild(td_child)
                })
                thead.appendChild(tr1)
                thead.appendChild(tr2)
                table.appendChild(thead)
            } else {
                let td = document.createElement('td')
                if (item.length) {
                    td.style.width = item.length + 'px'
                } else {
                    td.style.width = '100px'
                }
                td.setAttribute('colspan', 1)
                td.setAttribute('rowspan', item.rowspan)
                td.innerText = item.label
                tr1.appendChild(td)
            }
        })
    } else {
        let tr = document.createElement('tr')
        tr.style.height = '35px'
        header.forEach(item => {
            //添加表头
            let td = document.createElement('td')
            if (item.length) {
                td.style.width = item.length + 'px'
            } else {
                td.style.width = '100px'
            }
            td.innerText = item.label
            tr.appendChild(td)
        })
        thead.appendChild(tr)
        table.appendChild(thead)
    }
    // 由于可能存在多级表头，故需要对header中的key提取出来成为数组。然后后续直接从数组中获取值
    let key_list = []
    header.forEach(item => {
        if (item.children) {
            item.children.forEach(item_child => {
                key_list.push(item_child)
            })
        } else {
            key_list.push(item)
        }
    })
    // 添加表主体内容
    let tbody = document.createElement('tbody')

    data.forEach((data_item, index) => {
        data_item.index = 1 + index
        let tr = document.createElement('tr')
        tr.style.height = '35px'
        key_list.forEach(item => {
            let td
            if (item.value) {
                td = `<td style="mso-number-format:'\@'';">${item.value}</td>`
            } else {
                // 存在有的有效值在对象里面，需要通过a.b.c的方式获取
                if (/\./.test(item.key)) {
                    let key = item.key.split('.')
                    let value = key.reduce((pre, key_item) => {
                        return pre[key_item]
                    }, data_item)
                    td = `<td style="mso-number-format:'\@'';">${value}</td>`
                } else {
                    td = `<td style="mso-number-format:'\@'';">${data_item[item.key]}</td>`
                }
            }
            tr.insertAdjacentHTML('beforeend', td)
        })
        tbody.appendChild(tr)
    })
    table.appendChild(tbody)
    container.appendChild(table)
    let text = container.innerHTML
    //base64转码
    let base64 = function (html) {
        return window.btoa(unescape(encodeURIComponent(html)))
    }
    let uri = 'data:application/vnd.ms-excel;base64,'
    let html = `<!DOCTYPE html><html lang="en"><head><meta charset="UTF-8"></head><body style='text-align:center'>${text}</body></html>`
    let a = document.createElement('a')
    a.setAttribute('target', '_blank')
    a.setAttribute('download', name + '.xls')
    a.href = uri + base64(html)
    a.click()
    State.commit('changeLoading', {
        loading: false,
        title: '',
        color: 'rgba(255,255,255,0.8)'
    })
    Vue.prototype.$message({
        type: 'success',
        message: '导出excel成功',
        duration: 1500,
        showClose: true
    })
    a = undefined
    html = undefined
}

// 从身份证号码中获取出生日期
function getCsrqFromSfzhm(sfzhm) {
    if (sfzhm.length !== 18) {
        return ''
    } else {
        let time = sfzhm.slice(6, -4)
        let year = time.slice(0, 4)
        let month = time.slice(4, 6)
        let day = time.slice(6, 8)
        return `${year}-${month}-${day}`
    }
}


function filterHeader(columns) {
    console.log('columns', columns)
    return columns.map((col) => {
        // console.log(col)
        if (col.title != '操作') {

            let arr = []
            let obj = {
                header: col.label,
                // 用于数据匹配的 key
                key: col.key,
                // 列宽
                width: col.length ? Math.round(col.length / 100)*10 : 10,
                style: {
                    alignment: {vertical: 'middle', horizontal: 'center'}
                }
            }


            if (col.children) {
                obj.width = col.children.length * 15
                obj.children = col.children.map((item) => ({
                    header: item.label,
                    // 用于数据匹配的 key
                    key: item.key,
                    // 列宽
                    width: item.length ? item.length : 15,
                    style: {
                        alignment: {vertical: 'middle', horizontal: 'center'}
                    }
                }))
            }
            console.log(obj)
            return obj
        }
    })
}

export function getColumnNumber(width) {
    // 需要的列数，四舍五入
    return Math.round(width / 10);
}

function handleHeader(
    worksheet,
    headers,
    names1,
    names2
) {
    // 判断是否有 children, 有的话是两行表头
    const isMultiHeader = headers?.some(item => item.children);
    if (isMultiHeader) {
        // 加表头数据
        const rowHeader1 = worksheet.addRow(names1);
        const rowHeader2 = worksheet.addRow(names2);
        // 添加表头样式
        console.log('rowHeader1', rowHeader1)
        rowHeader1.eachCell((cell, colNum) => {
            cell.alignment = {vertical: 'middle', horizontal: 'center'}
        })

        rowHeader2.eachCell((cell, colNum) => {
            cell.alignment = {vertical: 'middle', horizontal: 'center'}
        })
        // addHeaderStyle(rowHeader1, {color: 'dff8ff'});
        // addHeaderStyle(rowHeader2, {color: 'dff8ff'});
        mergeColumnCell(headers, rowHeader1, rowHeader2, names1, names2, worksheet);
        return;
    }
    // 加表头数据
    const rowHeader = worksheet.addRow(names1);
    // 表头根据内容宽度合并单元格
    mergeRowCell(headers, rowHeader, worksheet);
    // 添加表头样式
    // addHeaderStyle(rowHeader, {color: 'dff8ff'});
    rowHeader.eachCell((cell, colNum) => {
        cell.alignment = {vertical: 'middle', horizontal: 'center'}
    })
}

function mergeColumnCell(
    headers,
    rowHeader1,
    rowHeader2,
    nameRow1,
    nameRow2,
    worksheet,
) {
    // 当前 index 的指针
    let pointer = -1;
    nameRow1.forEach((name, index) => {
        // 当 index 小于指针时，说明这一列已经被合并过了，不能再合并
        if (index <= pointer) return;
        // 是否应该列合并
        const shouldVerticalMerge = name === nameRow2[index];
        // 是否应该行合并
        const shouldHorizontalMerge = index !== nameRow1.lastIndexOf(name);
        pointer = nameRow1.lastIndexOf(name);
        if (shouldVerticalMerge && shouldHorizontalMerge) {
            // 两个方向都合并
            worksheet.mergeCells(
                Number(rowHeader1.number),
                index + 1,
                Number(rowHeader2.number),
                nameRow1.lastIndexOf(name) + 1,
            );
        } else if (shouldVerticalMerge && !shouldHorizontalMerge) {
            // 只在垂直方向上同一列的两行合并
            worksheet.mergeCells(Number(rowHeader1.number), index + 1, Number(rowHeader2.number), index + 1);
        } else if (!shouldVerticalMerge && shouldHorizontalMerge) {
            // 只有水平方向同一行的多列合并
            worksheet.mergeCells(
                Number(rowHeader1.number),
                index + 1,
                Number(rowHeader1.number),
                nameRow1.lastIndexOf(name) + 1,
            );
            // eslint-disable-next-line no-param-reassign
            const cell = rowHeader1.getCell(index + 1);
            cell.alignment = {vertical: 'middle', horizontal: 'center', wrapText: false};
        }
    });
}

function mergeRowCell(headers, row, worksheet) {
    // 当前列的索引
    let colIndex = 1;
    headers.forEach(header => {
        const {width, children} = header;
        if (children) {
            children.forEach(child => {
                colIndex += 1;
            });
        } else {
            // 需要的列数，四舍五入
            const colNum = getColumnNumber(width);
            // 如果 colNum > 1 说明需要合并
            if (colNum > 1) {
                worksheet.mergeCells(Number(row.number), colIndex, Number(row.number), colIndex + colNum - 1);
            }
            colIndex += colNum;
        }
    });
}

function addData2Table(worksheet, headerKeys, headers, list,col) {
    let headerRow = worksheet.getRow(1);
    headerRow.eachCell((cell, colnum) => {
        cell.alignment = {vertical: 'middle', horizontal: 'center', wrapText: false,};
    })
    list?.forEach((item, index) => {
        col.forEach((items) => {
            item[items.key] = items.value
        })
        item['index'] = index + 1
        const rowData = headerKeys?.map(key => item[key]);
        const row = worksheet.addRow(rowData);
        mergeRowCell(headers, row, worksheet);
        row.height = 20;
        // 设置行样式, wrapText: 自动换行
        row.alignment = {vertical: 'middle', horizontal: 'center', wrapText: false, shrinkToFit: false};
        row.font = {size: 11, name: '微软雅黑'};
    })
}

function downloadFile(columns, list, fileName) {
    // 创建工作簿
    const workbook = new ExcelJs.Workbook();
    // 添加sheet
    const worksheet = workbook.addWorksheet(fileName);
    // 设置 sheet 的默认行高
    worksheet.properties.defaultRowHeight = 20;
    // 设置列
    let arr = filterHeader(columns)

    // 第一行表头
    const names1 = [];
    // 第二行表头
    const names2 = [];
    // let arr = columns
    const headerKeys = [];
    arr.forEach(item => {
        if (item.children) {
            // 有 children 说明是多级表头，header name 需要两行
            item.children.forEach(child => {
                names1.push(item.header);
                names2.push(child.header);
                headerKeys.push(child.key);
            });
        } else {
            const columnNumber = getColumnNumber(item.width);
            for (let i = 0; i < columnNumber; i++) {
                names1.push(item.header);
                names2.push(item.header);
                headerKeys.push(item.key);
            }
        }
    });
    handleHeader(worksheet, arr, names1, names2);
    // console.log(filterHeader(columns))
    // worksheet.columns = arr.filter((item) => {
    //     return item != undefined
    // });
    let arr1 = JSON.parse(JSON.stringify(list))
    let arr2 = columns.filter((item) => {
        return item.value
    })
    addData2Table(worksheet, headerKeys, arr, arr1,arr2);

    worksheet.columns = worksheet.columns.map(col => ({...col, width: 20}));
    workbook.xlsx.writeBuffer().then((data => {
        const blob = new Blob([data], {type: ''});
        fs.saveAs(blob, fileName + '.xlsx');
    }))
    State.commit('changeLoading', {
        loading: false,
        title: '',
        color: 'rgba(255,255,255,0.8)'
    })
    Vue.prototype.$message({
        type: 'success',
        message: '导出excel成功',
        duration: 1500,
        showClose: true
    })
}

Vue.prototype.$exportExcel = downloadFile
// Vue.prototype.$exportExcel = tableToExcel