import Vue from 'vue'
// 获取本地存储
function get(key) {
  let value = localStorage.getItem(key)
  if (value) {
    return JSON.parse(value)
  } else {
    return value
  }
}
// 设置本地存储
function set(key, value) {
  return localStorage.setItem(key, JSON.stringify(value))
}

// 移除本地存储
function remove(key) {
  if (key) {
    return localStorage.removeItem(key)
  } else {
    return localStorage.clear()
  }
}
Vue.prototype.$myget = get
Vue.prototype.$myset = set
Vue.prototype.$myremove = remove

export { get, set, remove }
