import config from '@/config'
import State from '@/store'
import axios from 'axios'
import route from '@/router/index'
import { Message } from 'element-ui'
import { remove } from '@/plugin/storage'

const instance = axios.create({
  baseURL: config.prefix
})
instance.interceptors.response.use(
  res => {
    if (res.data.error !== 0) {
      Message.closeAll()
      Message.error({
        showClose: true,
        message: res.data.message,
        type: 'error',
        duration: 3000
      })
      return Promise.reject(res.data.message)
    } else {
      return Promise.resolve(res.data.data)
    }
  },
  error => {
    if (error.message === 'router_change') {
      return Promise.reject(error.message)
    }
    if (error.response.status === 401) {
      route.replace('/login')
      Message.closeAll()
      remove()
      location.reload()

      Message.error({
        showClose: true,
        message: '登录身份过期，请重新登录',
        type: 'error',
        duration: 3000
      })
      return new Error('登录身份过期，请重新登录')
    }
    Message.error({
      showClose: true,
      message: error.message,
      type: 'error',
      duration: 3000
    })
    return Promise.reject(error)
  }
)

instance.interceptors.request.use(options => {
  let { accountid, token } = State.getters
  if (token) {
    options.headers['Authorization'] = token
  }
  if (accountid) {
    options.headers['accountid'] = accountid
  }
  const CancelToken = axios.CancelToken
  let source = CancelToken.source()
  // 添加取消axios的标记
  State.commit('pushRequestList', source)
  options.cancelToken = source.token
  return options
})
export default instance
