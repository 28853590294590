export default [
  {
    path: '/',
    redirect: '/login'
  },
  {
    name: 'login',
    path: '/login',
    meta: {
      hidden: true,
      title: '登录'
    },
    component: () => import('@/views/login')
  },
  {
    name: 'layout',
    meta: {
      title: '首页',
      hidden: true
    },
    path: '/layout',
    component: () => import('@/layout'),
    children: []
  },
  {
    name: '404',
    path: '*',
    component: () => import('@/views/404'),
    children: []
  }
]
