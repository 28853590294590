/**
 * @functionName numberToString
 * @param { Object }
 * @param { Object }  table ，table的data字段
 * @param { Object }  table_field ，要转化的字段
 * @param { Array  }  dictionary，字典中对应字段的children
 * @Description  由于table中存在很多字段，表面是数字，实际上是字符串，需要转换后才可以展示。而数字与字符串的对应关系，存在于字典中的对应字段
 * @return { Object } table ,其中，转化后的字段为 table_field + _a
 */
export default function numberToString(table, table_field, dictionary) {
  let list = []
  tolist(dictionary)
  function tolist(dic) {
    if (dic instanceof Array) {
      dic.forEach(item => {
        if (item.has_child) {
          return tolist(item.children)
        } else {
          list.push(item)
        }
      })
    } else {
      if (dic.has_child) {
        return tolist(dic.children)
      } else {
        list.push(dic)
      }
    }
  }

  let result = table.map(table_item => {
    list.some(item => {
      if (item.value === table_item[table_field]) {
        table_item[table_field + '_a'] = item.label
        return true
      }
    })
    table.forEach(item => {
      if (item.has_child) {
        item.children = numberToString(item.children, table_field, list)
      }
    })
    return table_item
  })
  return result
}
