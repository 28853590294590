import { tongke } from '@/axios'
class Login {
  /**
   * @functionName userlogin
   * @param { Object }  params
   * @param { Object }  params.username
   * @param { Object }  params.password
   * @Description   // 用户登录
   * @return { Object }
   */
  userlogin(params) {
    return tongke.post('/l/login', params)
  }

  /**
   * @functionName identityList
   * @param { Object } params
   * @param { Number } params.page 页数
   * @param { Number } params.size  单页长度
   * @Description  // 身份列表
   * @return { Object }
   */
  identityList(params) {
    return tongke.get('/l/accounts', { params })
  }

  /**
   * @functionName  identityRoute
   * @param { Number } account_id 身份id
   * @Description   // 身份路由
   * @return { Object }
   */
  identityRoute(account_id) {
    return tongke.get(`/l/router2/${account_id}`)
  }

  // 检查完善资料，已经完善的话就返回true，否则返回false
  CheckPerfectInfo() {
    return tongke.get('/l/perfect_info')
  }

  /**
   * @functionName perfectInfo
   * @param { Object } params
   * @param { Object } params.old_password 旧密码
   * @param { Object } params.password  新密码
   * @param { Object } params.phone 手机号
   * @param { Object } params.realname  姓名
   * @param { Object } params.department  部门
   * @Description   // 完善资料
   * @return { Object }
   */
  perfectInfo(params) {
    return tongke.post('/l/perfect_info', params)
  }

  // 发送短信验证码
  requestSms(params) {
    return tongke.get('/u/smscode', params)
  }

  /**
   * @functionName verifySms
   * @param { Object } params
   * @param { String } params.code 短信验证码
   * @Description   // 验证短信验证码
   * @return { Object }
   */
  verifySms(params) {
    return tongke.post('/u/smscode', params)
  }

  /**
   * @functionName changePassword
   * @param { Object } params
   * @param { String } params.old_password 旧密码
   * @param { String } params.password  新密码
   * @Description // 修改密码
   * @return { Object }
   */
  changePassword(params) {
    return tongke.put('/l/change_pwd', params)
  }
}
export default new Login()
