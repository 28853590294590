export default {
  // 上传常住居民--需要有后台运行的功能
  namespaced: true,
  state: {
    componentName: '',
    progress: ''
  },
  mutations: {
    changeComponent(state, name) {
      state.name = name
    },
    setProgress(state, progress) {
      state.progress = progress
    }
  },
  actions: {},
  getters: {
    componentName: state => state.componentName,
    progress: state => state.progress
  }
}
